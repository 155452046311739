<template>
  <v-container class="bg-glass-effect px-3 pt-10" fluid>
     <BackArrow style="position: initial"/>
    <v-row  class="px-3">
      <v-col class="pt-7 pb-0" cols="12">
        <h1 class="generic-title ">Meus pagamentos</h1>
      </v-col>
      <v-col>
        <h3 class="mt-5 mb-8 font-weight-black">Feedback</h3>
        <h4 class="mt-5 mb-2 font-weight-black">{{ feedback.title }}</h4>
        <v-divider class="white mt-2"></v-divider>
        <v-col>
          <div class="font-weight-black" v-html="feedback.description"></div>
        </v-col>

      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import BackArrow from "@/components/covid/back-arrow";
import Assessment from "@/services/survey/Assessment";


export default {
  data: () => ({
    feedback: {
      title: null,
      description: null,
      image: null
    },
    feedbackId: null
  }),
 components: {
    BackArrow,
    //GenericBtn,
    //DatePicker
  },
  mounted() {
    if(this.$route.params && this.$route.params.feedback) {
      this.feedback = this.$route.params.feedback
    } else if(this.$route.query && this.$route.query.feedbackId){
      this.getFeedbackById(this.$route.query.feedbackId)
    }
    this.assessmentService = new Assessment()
  },
  methods: {
    ...mapMutations(["showmsg", "loading", "updateMenuState", "toogleMainDialog"]),

    getFeedbackById(id){
      this.loading(true);
      this.assessmentService.findFeedbackById(id).then((response) => {
        this.feedback.title = response.data.title
        this.feedback.description = response.data.description
        this.feedback.image = response.data.image
      }).catch(() => {
        this.showmsg({text: "Erro ao carregar feedback", type: "error"});
      }).finally(() => {
        this.loading(false)
      });
    }
  },

  computed: {
    ...mapState(["menu", "app"]),
    menur: {
      get: function () {
        return this.menu.rigth;
      },
      set: function (value) {
        this.updateMenuState({ rigth: value });
      },
    },
  },

};
</script>
